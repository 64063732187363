import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
List,
ListItem,
TextField,
Tab,
Tabs, TabsContainer
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../components/form';
import FormView from '../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import EregDataService from './EregData.service';
import NikValidateService from '../NikValidate/NikValidate.service';
import izitoast from 'izitoast'
import uploadService from '../Admin/upload/upload.service';
import KodeWilayah, { KodeWilayahView } from './KodeWilayah'
import KodeWilayahService from './KodeWilayah.service';
import VideoRecorder from 'react-video-recorder'

@reduxForm({form: 'EregDataForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formData: getFormValues('EregDataForm')(state),
  ...KodeWilayahService.stateConnectSetting()(state),
  user: state.auth.user
}))
export default class EregDataVal2 extends FormView {
service     = EregDataService
viewType    = 2
translate   = false
// showSubmit  = false

titleHeader() {
  return `VALIDASI TAHAP 2`;
}

initialData = {
}

constructor(props) {
  super(props)
  this.state = {
    onProgress: false,
    isError: false,
    errorMessage: '',
    tabIndex: 0,
    step: 0,
    videoStream: '',
    email: '',
    videoStreamFilename: ''
  }
}

async initData() {
  if(this.props.match.url.search("val2") >= 0) {
    let res = await this.service.api.me(this.props.match.params.id);
    if(!res.data.id) {
      res.data = {
      }
    } else {
      // res.data.wp.emailWp = this.props.user?this.props.user.email:''
    }

    this.setState({email: res.data.wp.emailWp})

    console.log(this.state, res.data, 'asdasdasdasd')

    this.props.initialize(res.data);
  } else {
    if(this.props.match.params.id == 'new') {
      this.initialData.wp.emailWp = this.props.user?this.props.user.email:''
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.wp.emailWp = this.props.user?this.props.user.email:''

      this.props.initialize(res.data);
    }

  }
}

handleNikValidate = async () => {
  if(this.props.formData.wp.kdNegaraWp == "ID") {
    var resData = await NikValidateService.api.validate({
      nik: this.props.formData.wp.noIDWp,
      noKK:  this.props.formData.wp.noKKWp,
      tempatLahir: this.props.formData.wp.tmpLahirWp,
      tanggalLahir: this.props.formData.wp.tglLahirWp
    })

    var validationMessage = ""
    if(resData.data.nik && resData.data.nik.search("Tidak") >= 0) validationMessage =  "data tidak sesuai"
    if(resData.data.tempatLahir && resData.data.tempatLahir.search("Tidak") >= 0) validationMessage = "Data tidak sesuai"
    if(resData.data.noKK && resData.data.noKK.search("Tidak") >= 0) validationMessage = "data tidak sesuai"
    if(resData.data.noKK && resData.data.tanggalLahir.search("Tidak") >= 0) validationMessage = "data tidak sesuai"

    if(validationMessage == "") {
      this.setState({tabIndex: this.state.tabIndex + 1})
    } else {
      iziToast.error({title: "Warning", message: validationMessage})
    }
  } else {
    if(!this.props.formData.wp.noKitasWp || this.props.formData.wp.noKitasWp == "") {
      iziToast.error({title: "Warning", message: "No kitas wajib di isi untuk warga negara asing"})
    } else {
      this.handleNextStep(1)
    }
  }
}

handleNextStep =(index)=> {

  if(!this.props.invalid) {
    this.setState((prevState, props)=> ({tabIndex: prevState.tabIndex+1}))
    if(index == this.state.step) {
      this.setState((prevState, props)=>({step: prevState.step+1}))
    }
  } else {
    iziToast.error({title: "Warning", message: "Mohon cek kembali form anda"})
  }
}

async beforeSave(value) {
  if(this.state.videoStream == '') {
    izitoast.warning({message: "Swavideo belum di rekam"})
    return false
  }

  return true
}

async handleSave(value) {
  var beforeSave = await this.beforeSave(value);
  var res = null;
  if(beforeSave) {
    if(typeof beforeSave === 'object') value = beforeSave;
    try {
      this.setState({onProgress: true})

      var formData = new FormData();
      formData.append('file', this.state.videoStream, "ekyc.webm")
      var upload = await uploadService.api.upload(formData);
      // send data
      var data = {
        emailWp: this.props.formData.wp.emailWp,
        email: this.props.formData.wp.emailWp,
        videoStreamFilename: upload.data.name
      }

      await timeout(1000)

      res = await this.service.api.val2(data)

      this.setState({onProgress: false})
      this.afterSave(res, value)

      var pathArray = this.props.location.pathname.split('/');
      var path = '';
      pathArray.forEach((d, i)=> {
        if(pathArray.length-1 != i) {
          path = path+d;
          if(i != pathArray.length-2) path = path+'/'
        }
      })


      if(res.data.status) {
        izitoast.success({message: "Validasi tahap 2 berhasil"})
      }

      this.props.history.goBack(path)
    } catch(e) {
      console.log(e, 'asdsad')
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }
}


formView() {
    return (<React.Fragment>
      <div className="gray hbox cross-center main-center">

     <Card style={{width: 640}}>
      <CardTitle title='' style={{padding: '10px 16px'}} />
        <Divider/>
        <div style={{height: 480 , width:640}}>
          <VideoRecorder
            // style={{height:400}}
            constraints={{video:{width:640, height:480}}}
            isOnInitially={false}
            mimeType={'video/webm'}
            showReplayControls
            replayVideoAutoplayAndLoopOff
            isReplayVideoInitiallyMuted={false}
            timeLimit={9000}
            onRecordingComplete={this._handleOnRecordingComplete}
          />
        </div>
      </Card>
      </div>
    </React.Fragment>
    )
  }

  _handleOnRecordingComplete = (data) => {
    this.setState({videoStream: data})
  }

  _upload = async (field) => {
    var value = await this.context.showDialog((props, res, rej) =>({
      title: 'Upload',
      width: 480,
      text: <div className="md-grid" >
          <Field
            label         = 'Lokasi'
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
            // validate      = {validation.required}
          />
        </div>
    }))

    if(value) {
      var formData = new FormData();
      formData.append('file', value.file)
      var res = await uploadService.api.upload(formData)

      this.props.change(field, res.data.name)

      console.log(res.data, 'asdasd')

    }
  }

  _kodeWilayahPick = async (field) => {
    var p = await this.context.showDialog((props, res, rej) =>({
      // title: 'Tambah Pembayaran',
      initialValue: {},
      height: 'auto',
      width: 800,
      contentStyle: {overflow: 'inherit', padding: 0},
      okText: 'Pilih',
      actions: [],
      text: <KodeWilayahPicker pilih={(d)=> {
        if(field == 'ktp.almKTPKdWilayah') {
          this.props.change('domisili.almDomisiliKdWilayah', d.id)
        }
        this.props.change(field, d.id)

        res({})
      }} />

    }))

    if(p) {
      if( this.props.table.selected.length > 0) {
        this.props.change(field, this.props.table.selected[0].id)
      }
      // this.setState({pembayaranList: this.props.table.selected})

    }


  }
}

class PhotoImg extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      url: ''
    }
  }

  componentDidMount() {
    this.updateImage()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.updateImage();
    }
  }

  updateImage = async () => {
    var res = await uploadService.api.download(this.props.src)
    // console.log(res, 'asdasd')

    this.setState({url:URL.createObjectURL(res.data)})
  }

  render() {
    return <img style={this.props.style} src={this.state.url} />
  }
}

@connect(KodeWilayahService.stateConnectSetting(), KodeWilayahService.actionConnectSetting())
class KodeWilayahPicker extends KodeWilayahView {
  viewType=2;
  selectableRows=false

  columns=[
    {isSortable: false,  show: true,   isSearchable:false,  label: `word.actions`,       value: (d)=> {
      return <Button secondary flat swapTheming onClick={()=> this.props.pilih(d)} >Pilih</Button>
    }, type: "func"},

    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.id`,       value: "id"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.name`,       value: "name"},
    {isSortable: false,  show: false,   isSearchable:false,  label: `entities.${this.service.name}.noProp`,       value: "noProp"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.namaProp`,       value: "namaProp"},
    {isSortable: false,  show: false,   isSearchable:false,  label: `entities.${this.service.name}.noKab`,       value: "noKab"},
    {isSortable: false,  show: true,   isSearchable:false,  label: `entities.${this.service.name}.namaKab`,       value: "namaKab"},
    {isSortable: false,  show: false,   isSearchable:false,  label: `entities.${this.service.name}.noKec`,       value: "noKec"},
    {isSortable: false,  show: true,   isSearchable:false,  label: `entities.${this.service.name}.namaKec`,       value: "namaKec"},
    {isSortable: false,  show: false,   isSearchable:false,  label: `entities.${this.service.name}.noKel`,       value: "noKel"},

  ]

  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
