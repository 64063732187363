import ApiService from '../../../services/api.service';

class LogProcessService extends ApiService {
  name  = 'logValidasi';
  path  = 'log';
  constructor() {
    super();
    this.init()
  }

  api = {
    download: async (d, check) => {
      var c = {
        responseType: 'blob'
      }
      if(check) c = {}
      var res = await this.http.post(`${this.apiLocation}/${this.path}-export`, d, {
        responseType: 'blob'
      })

      return res;
    },
    downloadError: async (d, check) => {
      var c = {
        responseType: 'blob'
      }
      if(check) c = {}

      var res = await this.http.get(`${this.apiLocation}/${this.path}/download-error/${d.id}`, c)

      return res;
    }
  }
}


export default new LogProcessService();
