import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import EregDataService from './EregData.service';
import ListView from '../../../components/entity/listView';
// import EregDataDialog from './EregData.dialog'
import download from "downloadjs";

@connect(EregDataService.stateConnectSetting(), EregDataService.actionConnectSetting())
export default class EregDataView extends ListView {
  editDialog  = false;
  addDialog   = false;
  service     = EregDataService
  FormDialog  = ()=> <div />

  columns=[
      {isSortable: true,    show: true,   isSearchable:false,    label: "entities.EregData.namaWp",                value: "wp.namaWp"},
      {isSortable: true,    show: true,   isSearchable:false,    label: "entities.EregData.nomorHPWp",                value: "wp.nomorHPWp"},
      {isSortable: true,    show: true,   isSearchable:false,    label: "entities.EregData.emailWp",                value: "wp.emailWp"},
      {isSortable: true,    show: true,   isSearchable:false,    label: "entities.EregData.noIDWp",                value: "wp.noIDWp"},
      {isSortable: true,    show: true,   isSearchable:false,    label: "entities.EregData.status",                value: "status"},
      {isSortable: true,    show: true,   isSearchable:false,    label: "entities.EregData.message",                value: "message"},
  ]
}
