import ApiService from '../../../services/api.service';

class NikValidateService extends ApiService {
  name  = 'NikValidate';
  path  = 'nik-validate';
  constructor() {
    super();
    this.init()
  }

  api = {
    validate: async (d) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, d)

      return res;
    },
    validateWithDataBalikan: async (d) => {
      var res = await this.http.post(`${this.apiLocation}/nik-validate-balikan`, d)

      return res;
    }
  }
}


export default new NikValidateService();
