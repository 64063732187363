/**
 * Created by dwiargo on 11/29/17.
 */

import env from 'env';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EditProfileForm from './EditProfile/EditProfile.form';
import EditPasswordForm from './EditPassword/EditPassword.form';

const basePath = function() {
  if(env.devMode) {
    return ''
  } else {
    return ''
    // return '/company/:companyId/:subscriptionId/init'
  }
}()

export default (actions, global) => (
  <Switch>
    {/* Admin */}
    <Route path={basePath+"/reg_npwp/edit"} component={require('./EregData/EregData.form').default}/>

    <Route path={basePath+"/reg_npwp/val1"} component={require('./EregData/EregData.val1').default}/>
    <Route path={basePath+"/reg_npwp/val2"} component={require('./EregData/EregData.val2').default}/>

    <Route path={basePath+"/reg_npwp"} component={require('./EregData/EregData.front').default}/>
    <Route path={basePath+"/EregData/:id"} component={require('./EregData/EregData.form').default}/>
    <Route path={basePath+"/EregData"} component={require('./EregData/EregData.view').default}/>
    <Route path={basePath+"/npwp"} component={require('./NpwpNik/NpwpNik.view').default}/>
    <Route path={basePath+"/log-validasi"} component={require('./LogProcess/LogProcess.view').default}/>
    <Route path={basePath+"/validasi-balikan"} component={require('./NikValidate/NikValidateBalikan.form').default}/>
    <Route path={basePath+"/"} component={require('./EregData/EregData.front').default}/>
  </Switch>
)





