import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
List,
ListItem,
DataTable,
TableHeader,
TableBody,
TableRow,
TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch} from '../../../components/form';
import FormView from '../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import NikValidateService from './NikValidate.service';

@reduxForm({form: 'NikValidateForm', destroyOnUnmount: true, initialValues: {response: {}}})
@connect((state) => ({
data: getFormValues('NikValidateForm')(state)
}))
export default class NikValidateDialog extends FormView {
service     = NikValidateService
showSubmit  = false
cancelLabel = 'Tutup'
viewType    = 2
translate = false;
titleHeader() {
  return `Validasi NIK & Data Balikan`;
}

initialData = {
  noKK: "",
  tempatLahir: "",
  tanggalLahir: ""
}

_barItem() {
  const { handleSubmit, submitting, valid, pristine } = this.props;

  return (
    <div>
      <Button flat primary onClick={()=> this.initData()}>Clear</Button>
      <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Validasi</Button>
    </div>
  )
}

formView() {
    return (<div>
    <Card>
      <div className='md-grid'>
        <Field
        label         = {counterpart('entities.NikValidate.nik')}
        name          = 'nik'
        className     = 'md-cell md-cell--12'
        component     = {TextfieldMask}
        maskFormat    = "################"
        mask          = "_"
        length        = {16}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.NikValidate.noKK')}
        name          = 'noKK'
        className     = 'md-cell md-cell--12'
        maskFormat    = "################"
        component     = {TextfieldMask}
        mask          = "_"
        length        = {16}
        // validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.NikValidate.tempatLahir')}
        name          = 'tempatLahir'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        // validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.NikValidate.tanggalLahir')}
        name          = 'tanggalLahir'
        className     = 'md-cell md-cell--6'
        component     = {Datepicker}
        // validate      = {validation.required}
        />

        <Field
        label         = {"Data Balikan NPWP"}
        name          = 'npwp'
        className     = 'md-cell md-cell--12'
        maskFormat    = "##.###.###.#-###-###"
        mask          = "_"
        length        = {15}
        component     = {TextfieldMask}
        // validate      = {validation.required}
        />
      </div>
    </Card>

    <br/>
    {this.state.showResponse && <Card>
      <DataTable plain>
        {/* <TableHeader>
          <TableRow>
            <TableColumn>Lorem 1</TableColumn>
            <TableColumn>Lorem 2</TableColumn>
          </TableRow>
        </TableHeader> */}
        <TableBody>
          <TableRow>
            <TableColumn>Status</TableColumn>
            <TableColumn>{this.state.response.status}</TableColumn>
          </TableRow>
          <TableRow>
            <TableColumn>Pesan</TableColumn>
            <TableColumn>{this.state.response.respon}</TableColumn>
          </TableRow>
          <TableRow>
            <TableColumn>NIK</TableColumn>
            <TableColumn>{this.state.response.nik}</TableColumn>
          </TableRow>
          <TableRow>
            <TableColumn>NO KK</TableColumn>
            <TableColumn>{this.state.response.noKK}</TableColumn>
          </TableRow>
          <TableRow>
            <TableColumn>Tempat Lahir</TableColumn>
            <TableColumn>{this.state.response.tempatLahir}</TableColumn>
          </TableRow>
          <TableRow>
            <TableColumn>Tanggal Lahir</TableColumn>
            <TableColumn>{this.state.response.tanggalLahir}</TableColumn>
          </TableRow>

          <TableRow>
            <TableColumn>Respon Data Balikan</TableColumn>
            <TableColumn>{this.state.response.dataBalikanStatus}</TableColumn>
          </TableRow>
        </TableBody>
      </DataTable>
    </Card>}
    </div>
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      response: {},
      showResponse: false
    }
  }

  async initData() {
    this.setState({showResponse: false})
    this.props.initialize(this.initialData);
  }

  async handleSave(value) {
    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        res = await this.service.api.validateWithDataBalikan(value)

        this.setState({response: res.data, showResponse: true})

        this.setState({onProgress: false})
        this.afterSave(res, value)

      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }
}
