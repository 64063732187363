import moment from 'moment';

export const required = value => value == null || value == '' ? 'Diperlukan' : null
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : null
export const maxLength7 = maxLength(7)
export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
export const minValue18 = minValue(18)
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Email tidak sesuai' : null
export const tooOld = value =>
  value && value > 65 ? 'You might be too old for this' : undefined
export const aol = value =>
  value && /.+@aol\.com/.test(value) ?
  'Really? You still use AOL for your email?' : undefined

export const angka = value => value && !/^\d+$/.test(value) ? 'Harus di isi dengan angka' : undefined

export const tidakBoleh0 = (value) => {
  // var err = "Angka Pertama Tidak Boleh 0"
  if(value) {
    if(value.charAt(0) == '0') {return "Angka Pertama Tidak Boleh 0"} else return null
  }

  return null

}

export const umurTidakBolehDibawahDari18Tahun = (value) => {
  var umur = moment().diff(value, 'years');
  if(umur < 18) {
    return "Umur OP harus lebih dari 17 tahun"
  } else {
    return null
  }
}

export const nikharus16 = (value) => {
  // var err = "Angka Pertama Tidak Boleh 0"
  if(value) {
    if(value.length < 16) {return "NIK harus 16 digit"} else return null
  }

  return null

}

export const angkaterakhir = (value) => {
  // var err = "Angka Pertama Tidak Boleh 0"
  if(value) {
    if(value.length >= 4) {
      if(value.slice(-4) === "0000") {
        return "4 Angka terakhir Tidak Boleh 0000"
      }
    } else return null
  }

  return null

}

export const checkGelar = (value) => {
  if(value) {
    // console.log(new RegExp(gelar.join("|")).test(value), 'asdasd')
    if (new RegExp(gelar.map((x, i)=> x.toLowerCase()).join("|")).test(value.toLowerCase())) {
      return "Untuk gelar silakan di isi pada kolom gelar depan atau gelar belakang"

    } return null
  }

  return null
}


const gelar = [
  "DR\\.",
  "dr\\.",
  "Drs\\.",
  "Dra\\.",
  "Ir\\.",
  "Hj\\.",
  "Prof\\.",

  "M\\.Ag\\.",
"M\\.E\\.",
"M\\.E\\.I\\.",
"M\\.Fhil\\.",
"M\\.Fil\\.I\\.",
"M\\.H\\.",
"M\\.H\\.I\\.",
"M\\.Hum\\.",
"M\\.Kes\\.",
"M\\.Kom\\.",
"M\\.M\\.",
"M\\.P\\.",
"M\\.Pd\\.",
"M\\.Pd\\.I\\.",
"M\\.Psi\\.",
"M\\.Si\\.",
"M\\.Sn\\.",
"M\\.T\\.",
"S\\.Ag\\.",
"S\\.E\\.",
"S\\.Fil\\.",
"S\\.Fil\\.I\\.",
"S\\.H\\.",
"S\\.H\\.I\\.",
"S\\.Hum\\.",
"S\\.I\\.P\\.",
"S\\.K\\.M\\.",
"S\\.Ked\\.",
"S\\.Kes\\.",
"S\\.Kom\\.",
"S\\.P\\.",
"S\\.Pd\\.",
"S\\.Pd\\.I\\.",
"S\\.Psi\\.",
"S\\.Pt\\.",
"S\\.S\\.",
"S\\.Si\\.",
"S\\.Sn\\.",
"S\\.Sos\\.",
"S\\.Sos\\.I\\.",
"S\\.T\\.",
"S\\.Th\\.",
"S\\.Th\\.I\\.",
"Sp\\.BP",
"Sp\\.OG",
"A\\.Md\\.",
"Ak\\.",
"Dr\\.",
"drg\\.",
"M\\.A\\.R\\.S\\.",
"M\\.AB",
"M\\.Ag\\.",
"M\\.Ak\\.",
"M\\.AP",
"M\\.Cs\\.",
"M\\.Econ",
"M\\.Farm\\.",
"M\\.H\\.",
"M\\.Hum\\.",
"M\\.Hut\\.",
"M\\.Kes",
"M\\.Kes\\.",
"M\\.Kn\\.",
"M\\.Kom\\.",
"M\\.M\\.",
"M\\.MPd\\.",
"M\\.P\\.H\\.",
"M\\.Pd\\.",
"M\\.Psi\\.",
"M\\.Si\\.",
"M\\.Sn",
"M\\.Stat\\.",
"M\\.T\\.",
"M\\.TI\\.",
// "MA",
"MMSI\\.",
"MSA",
"MSEE",
"MSIE",
"Ners",
"S\\. E\\. I\\.",
"S\\. H\\. I\\.",
"S\\. Sn",
"S\\.AB\\.",
"S\\.Ag\\.",
"S\\.AP",
"S\\.E\\.",
"S\\.Fam\\.",
"S\\.Farm\\.",
"S\\.Gz",
"S\\.H\\.",
"S\\.Hut\\.",
"S\\.I\\.Kom\\.",
"S\\.I\\.P\\.",
"S\\.IK\\.",
"S\\.IP",
"S\\.K\\.M\\.",
"S\\.Ked",
"S\\.Ked\\.",
"S\\.Kel\\.",
"S\\.Kep\\.",
"S\\.Kom\\.",
"S\\.Mb",
"S\\.P\\.",
"S\\.Pd\\.",
"S\\.Pd\\.I\\.",
"S\\.Pi\\.",
"S\\.Psi\\.",
"S\\.Pt",
"S\\.PT\\.",
"S\\.S",
"S\\.Si\\.",
"S\\.Sl\\.",
"S\\.Sos",
"S\\.Sos\\.",
"S\\.STP",
"S\\.Sy\\.",
"S\\.T\\.",
"S\\.T\\.P\\.",
"S\\.Th\\.",
"S\\.Th\\.I\\.",
"S\\.TP\\.",
"SKG\\.",
"Sp\\. OT",
"Sp\\.A",
"Sp\\.An",
"Sp\\.An\\.",
"Sp\\.And",
"Sp\\.B",
"Sp\\.B KBD",
"Sp\\.B\\.Onk",
"Sp\\.BA",
"Sp\\.BK",
"Sp\\.BM",
"Sp\\.BO",
"Sp\\.BP",
"Sp\\.BS",
"Sp\\.BTKV",
"Sp\\.BU",
"Sp\\.F",
"Sp\\.FK",
"Sp\\.G",
"Sp\\.GK",
"Sp\\.JP",
"Sp\\.KG",
"Sp\\.KGA",
"Sp\\.KJ",
"Sp\\.KK",
"Sp\\.KN",
"Sp\\.KO",
"Sp\\.KP",
"Sp\\.M",
"Sp\\.M\\.",
"Sp\\.MK",
"Sp\\.OG",
"Sp\\.OG\\.",
"Sp\\.Ok",
"Sp\\.Ort",
"Sp\\.OT",
"Sp\\.P",
"Sp\\.PA",
"Sp\\.PA\\.",
"Sp\\.ParK",
"Sp\\.PD",
"Sp\\.PD\\.",
"Sp\\.Perio",
"Sp\\.PK",
"Sp\\.PS\\.",
"Sp\\.R",
"Sp\\.RM",
"Sp\\.S",
"Sp\\.THT-KL",
"Sp.U",
"SpPD-KHOM"
]
