import ApiService from '../../../services/api.service';

class EregDataService extends ApiService {
  name  = 'EregData';
  path  = 'ereg_data';
  constructor() {
    super();
    this.init()
  }

  api = {
    me: async ()=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}_me`)
      return res
    },
    ocr: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/ereg/get-data-ktp`, data)
      return res
    },
    cekNik: async (nik)=> {
      let res = await this.http.get(`${this.apiLocation}/ereg/cek-npwp-by-nik/${nik}`)
      return res
    },
    cekOpPhMt: async (npwpSuami, nikSuami, noKKWp)=> {
      let res = await this.http.get(`${this.apiLocation}/ereg/cek-op-ph-mt/${npwpSuami}/${nikSuami}/${noKKWp}`)
      return res
    },
    cekNpwpPusat: async (npwp)=> {
      let res = await this.http.get(`${this.apiLocation}/ereg/cek-npwp-pusat/${npwp}`)
      return res
    },
    val1: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/ereg/validasi-step-1`, data)
      return res
    },
    val2: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/ereg/validasi-step-2`, data)
      return res
    },
    updateMe: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}_me`, data)
      return res
    },
    send: async (id)=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}_send/${id}`)
      return res
    },
  }
}


export default new EregDataService();
