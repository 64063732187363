import {
  auth, table, global, toast, dialog, tmpObject
} from 'react-mpk/redux/reducers';
import { reducer as formReducer } from 'redux-form'
import image from './imageReducer';

import {combineReducers} from 'redux';

const entityReducer = combineReducers({
  logValidasi                       : require('../../modules/Main/LogProcess/LogProcess.service').default.reducer,
  NpwpNik                           : require('../../modules/Main/NpwpNik/NpwpNik.service').default.reducer,
  EregData                          : require('../../modules/Main/EregData/EregData.service').default.reducer,
  KodeWilayah                          : require('../../modules/Main/EregData/KodeWilayah.service').default.reducer
  // END OF PAYROLL ----------------------------------------------------------
})

const rootReducer = combineReducers({
  auth, table, global, toast,
  image, dialog, tmpObject, global,
  form: formReducer,
  auth: auth,
  authHalona: require('../../services/auth.service').default.reducer,
  entity: entityReducer
});

export default rootReducer;
