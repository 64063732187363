/**
 * @author Ismail Sunny
 * @email ismiletea@gmail.com
 * @create date 2018-01-17 12:17:15
 * @modify date 2018-01-17 12:17:15
 * @desc [description]
*/
import _ from 'lodash';
import http from './http.service';
import Service from './service';

export default class TableService extends Service {
  reducer;

  tableType = {
    GLOBAL_SET_PROPERTIES : "GLOBAL_SET_PROPERTIES",
    GLOBAL_TOGGLE_PROPERTY : "GLOBAL_TOGGLE_PROPERTY",
    TABLE_SET_DATA : "TABLE_SET_DATA",
    TABLE_SET_COLUMNS : "TABLE_SET_COLUMNS",
    TABLE_SET_PROPERTIES : "TABLE_SET_PROPERTIES",
    TABLE_UPDATE_COLUMN : "TABLE_UPDATE_COLUMN",
    TABLE_UPDATE_PARAMS : "TABLE_UPDATE_PARAMS",
    TABLE_ADD_DATA_ITEM : "TABLE_ADD_DATA_ITEM",
    TABLE_DELETE_DATA_ITEM : "TABLE_DELETE_DATA_ITEM",
    TABLE_DELETE_DATA_ITEMS : "TABLE_DELETE_DATA_ITEMS",
    TABLE_UPDATE_DATA_ITEM : "TABLE_UPDATE_DATA_ITEM",
    TABLE_DELETE_SELECTED_DATA_ITEMS : "TABLE_DELETE_SELECTED_DATA_ITEMS",
    TABLE_SELECT_DATA_ITEM : "TABLE_SELECT_DATA_ITEM",
    TABLE_DESELECT_DATA_ITEM : "TABLE_DESELECT_DATA_ITEM",
    TABLE_RESET_ALL : "TABLE_RESET_ALL",
    TABLE_RESET : "TABLE_RESET",
    TABLE_RESET_PARAMS : "TABLE_RESET_PARAMS",
  }

  tableAction = {
    setData: (data:Array) => {},
    setColumns: (columns:Array) => {},
    setProperties: (properties:object) => {},
    updateParams: (params:object) => {},
    updateColumn: (column:object, update:object) => {},
    addDataItem: (item:Object) => {},
    deleteDataItem: (item:Object) => {},
    deleteDataItems: () => {},
    deleteSelectedDataItems: () => {},
    updateDataItem: (index:Number, update:Object) => {},
    selectDataItem: (index:Number, isSelect:Boolean) => {},
    deselectDataItem: () => {},
    resetAll: () => {},
    reset: (keys:Array) => {},
    resetParams: () => {}
  }

  initialTableState = {
    columns:[],
    data:[],
    isLoading:false,
    itemActions:[],
    params:{
      page:1,
      size:10,
      column:'',
      keyword:'',
      sortBy:'id',
      sort:'DESC',
      total:0,
      startDate:'',
      endDate:''
    },
    confirmation:{},
    selected:[],
    selectedItem:null,
    error:{
      isError:false,
      title:'',
      message:''
    }
  }

  tableReducer() {
    var obj = this;
    let idx, data, columns, target, item, update, selected;
    var initialTableState = _.cloneDeep(obj.initialTableState);

    return function(state = initialTableState, action = {}) {
      switch (action.type){
        case obj.tableType.TABLE_SET_PROPERTIES:
          if(action.properties.params) {
            action.properties.params = _.merge(state.params, action.properties.params)
          }
          return {
            ...state,
            ...action.properties
          }

        case obj.tableType.TABLE_SET_DATA:
          return Object.assign({}, state, {
            data:action.data
          });

        case obj.tableType.TABLE_SET_COLUMNS:
          return Object.assign({}, state, {
            columns:action.columns
          });

        case obj.tableType.TABLE_UPDATE_COLUMN:
          columns = state.columns;
          target = _.find(columns, action.column);
          update = _.merge(target, action.update);
          idx = columns.indexOf(target);
          columns.splice(idx, 1, update);

          return Object.assign({}, state, {
            columns:columns
          });

        case obj.tableType.TABLE_UPDATE_PARAMS:
          let params = _.merge(state.params, action.params);
          return Object.assign({}, state, {
            params:params
          });

        case obj.tableType.TABLE_ADD_DATA_ITEM:
          return Object.assign({}, state, {
            data:state.params.sort === 'ASC' ? [action.item, ...state.data] : [...state.data, action.item],
            total:state.total + 1
          });

        case obj.tableType.TABLE_DELETE_DATA_ITEM:
          data = state.data;
          item = _.find(data, action.item);
          idx = data.indexOf(item);
          data.splice(idx, 1);

          selected = state.selected;
          item = _.find(selected, action.item);
          idx = selected.indexOf(item);
          selected.splice(idx,1);

          return Object.assign({}, state, {
            data:data,
            total:state.total - 1,
            selected:selected
          });

        case obj.tableType.TABLE_DELETE_DATA_ITEMS:
          return state;

        case obj.tableType.TABLE_DELETE_SELECTED_DATA_ITEMS:
          data = state.data.filter(d => {
            item = _.find(state.selected, d);
            return item ? false : true;
          });
          return Object.assign({}, state, {
            data:data,
            selected:[],
            total:state.total - data.length
          });

        case obj.tableType.TABLE_UPDATE_DATA_ITEM:
          if(action.index > 0) {
            data = state.data;
            idx = action.index - 1;;
            item = data[idx];
            update = _.merge(item, action.update);
            data.splice(idx, 1, update);
          } else if(action.index === 0){
            data = state.data.map(function(d){
              update = _.merge(d, action.update);
              return update;
            })
          }

          return Object.assign({}, state, {
            data:data
          });

        case obj.tableType.TABLE_SELECT_DATA_ITEM:
          if(action.index > 0) {
            item = state.data[action.index - 1];
            selected = state.selected;
            let _item = _.find(selected, item);
            idx = selected.indexOf(_item);
            if (action.isSelect) {
              if(idx < 0) selected.push(item);
            } else {
              if(idx >= 0) selected.splice(idx , 1);
            }
          } else {
            if(action.isSelect) {
              selected = state.data.map(function (d) {
                return d;
              })
            } else {
              selected = [];
            }
          }
          return Object.assign({}, state, {
            selected:selected
          });

        case obj.tableType.TABLE_RESET_ALL:
          return Object.assign({}, state, obj.initialTableState);

        case obj.tableType.TABLE_RESET:
          let _initialState = obj.initialTableState;
          let updatedState = {};
          for(var i = 0 ; i < action.keys.length ; i++){
            let key = action.keys[i];
            updatedState[key] = _initialState[key];
          }
          return Object.assign({}, state, updatedState);

        case obj.tableType.TABLE_DESELECT_DATA_ITEM:
          return Object.assign({}, state, {
            selectedItem:null
          });

        case obj.tableType.TABLE_RESET_PARAMS:
          let newParams = obj.initialTableState.params;
          return Object.assign({}, state, {
            params:newParams
          });

        default:
          return state;
      }
    }

  }

  // method
  constructor(name:String) {
    super();
    this.name = name;
    this.nameUpper = name.toUpperCase()
    this.generateType()
    this.generateTableAction()
		this.initReducers()
  }

  initReducers() {
    this.reducer = this.tableReducer()
  }

  generateType() {
    this.tableType.GLOBAL_SET_PROPERTIES = this.nameUpper+"/GLOBAL_SET_PROPERTIES";
    this.tableType.GLOBAL_TOGGLE_PROPERTY = this.nameUpper+"/GLOBAL_TOGGLE_PROPERTY";

    this.tableType.TABLE_SET_DATA = this.nameUpper+"/TABLE_SET_DATA";
    this.tableType.TABLE_SET_COLUMNS = this.nameUpper+"/TABLE_SET_COLUMNS";
    this.tableType.TABLE_SET_PROPERTIES = this.nameUpper+"/TABLE_SET_PROPERTIES";
    this.tableType.TABLE_UPDATE_COLUMN = this.nameUpper+"/TABLE_UPDATE_COLUMN";
    this.tableType.TABLE_UPDATE_PARAMS = this.nameUpper+"/TABLE_UPDATE_PARAMS";
    this.tableType.TABLE_ADD_DATA_ITEM = this.nameUpper+"/TABLE_ADD_DATA_ITEM";
    this.tableType.TABLE_DELETE_DATA_ITEM = this.nameUpper+"/TABLE_DELETE_DATA_ITEM";
    this.tableType.TABLE_DELETE_DATA_ITEMS = this.nameUpper+"/TABLE_DELETE_DATA_ITEMS";
    this.tableType.TABLE_UPDATE_DATA_ITEM = this.nameUpper+"/TABLE_UPDATE_DATA_ITEM";
    this.tableType.TABLE_DELETE_SELECTED_DATA_ITEMS = this.nameUpper+"/TABLE_DELETE_SELECTED_DATA_ITEMS";
    this.tableType.TABLE_SELECT_DATA_ITEM = this.nameUpper+"/TABLE_SELECT_DATA_ITEM";
    this.tableType.TABLE_DESELECT_DATA_ITEM = this.nameUpper+"/TABLE_DESELECT_DATA_ITEM";
    this.tableType.TABLE_RESET_ALL = this.nameUpper+"/TABLE_RESET_ALL";
    this.tableType.TABLE_RESET = this.nameUpper+"/TABLE_RESET";
    this.tableType.TABLE_RESET_PARAMS = this.nameUpper+"/TABLE_RESET_PARAMS";
  }

  generateTableAction() {
    var types = this.tableType;
    this.tableAction = {
      setData: (data:Array) => {
        return {
          type:types.TABLE_SET_DATA,
          data
        }
      },
      setColumns: (columns:Array) => {
        return {
          type:types.TABLE_SET_COLUMNS,
          columns
        }
      },
      setProperties: (properties:object) => {
        return {
          type:types.TABLE_SET_PROPERTIES,
          properties
        }
      },
      updateParams: (params:object) => {
        return {
          type:types.TABLE_UPDATE_PARAMS,
          params
        }
      },
      updateColumn: (column:object, update:object) => {
        return {
          type:types.TABLE_UPDATE_COLUMN,
          column, update
        }
      },
      addDataItem: (item:Object) => {
        return {
          type:types.TABLE_ADD_DATA_ITEM,
          item
        }
      },
      deleteDataItem: (item:Object) => {
        return {
          type:types.TABLE_DELETE_DATA_ITEM,
          item
        }
      },
      deleteDataItems: () => {
        return {
          type:types.TABLE_DELETE_DATA_ITEMS
        }
      },
      deleteSelectedDataItems: () => {
        return {
          type:types.TABLE_DELETE_SELECTED_DATA_ITEMS
        }
      },
      updateDataItem: (index:Number, update:Object) => {
        return {
          type:types.TABLE_UPDATE_DATA_ITEM,
          index, update
        }
      },
      selectDataItem: (index:Number, isSelect:Boolean) => {
        return {
          type:types.TABLE_SELECT_DATA_ITEM,
          index, isSelect
        }
      },
      deselectDataItem: () => {
        return {
          type:types.TABLE_DESELECT_DATA_ITEM
        }
      },
      resetAll: () => {
        return {
          type:types.TABLE_RESET_ALL
        }
      },
      reset: (keys:Array) => {
        return {
          type:types.TABLE_RESET,
          keys
        }
      },
      resetParams: () => {
        return {
          type:types.TABLE_RESET_PARAMS
        }
      }
    }
  }
}
