import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  CardActions,
  Avatar,
List,
ListItem,
TextField,
Tab,
Tabs, TabsContainer, Checkbox
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../components/form';
import FormView from '../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import EregDataService from './EregData.service';
import NikValidateService from '../NikValidate/NikValidate.service';
import env from 'env'
import uploadService from '../Admin/upload/upload.service';

import parse from 'html-react-parser'
import Eula from './Eula';

import {StatusData} from '../../../config/constant'

@reduxForm({form: 'EregDataForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formData: getFormValues('EregDataForm')(state)
}))
export default class EregDataFront extends FormView {
  service     = EregDataService
  viewType    = 2
  translate   = false

  titleHeader() {
    return `Permohonan Pendaftaran NPWP`;
  }

  formView() {
    var statusColor = "#939393"
    if(this.state.status == "DATA SIAP DI KIRIM") statusColor = "#03A9F4"
    if(this.state.status == "GAGAL") statusColor = "#F06292"
    if(this.state.status == "BERHASIL") statusColor = "#66BB6A"
    return <div className="md-grid" style={{padding: 0}}>
      <Card className="md-cell md-cell--12">
        <CardTitle
          title="STATUS PERMOHONAN"
          // subtitle="Card Subtitle"
          // avatar={<Avatar src='' role="presentation" />}
        />

        <div className="md-card-title--title md-card-title--large md-text" style={{padding: 15, fontSize: 18, background: statusColor, textAlign: 'center'}}>
          {this.state.status}
        </div>
      </Card>



      <div className="md-cell md-cell--6">
        <Card>
          {/* <div
            title={}
            subtitle={this.state.data.wp.namaWp}
            // avatar={<Avatar src={this.state.url} role="presentation" />}
          > */}
            <div className="hbox md-card-title md-card-title--primary">
              <div className="md-card-title--title md-card-title--large md-text">VALIDASI TAHAP 1</div>
              <div className="flex" />
              {(this.state.status == StatusData.val1Berjalan || this.state.status == StatusData.val1Gagal) && <Button icon primary swapTheming onClick={()=> {
                if(this.rootPath) {
                  this.props.history.push(this.rootPath+"/val1");
                } else {
                  this.props.history.push('/reg_npwp/val1');
                }
              }}>navigate_next</Button>}
               {!(this.state.status == StatusData.val1Berjalan || this.state.status == StatusData.val1Gagal) && <Button icon primary style={{backgroundColor: '#4caf50'}} swapTheming>check</Button>}
            </div>
          <Divider/>
        </Card>

        <br/>

        <Card>
          {/* <div
            title={}
            subtitle={this.state.data.wp.namaWp}
            // avatar={<Avatar src={this.state.url} role="presentation" />}
          > */}
            <div className="hbox md-card-title md-card-title--primary">
              <div className="md-card-title--title md-card-title--large md-text" >VALIDASI TAHAP 2</div>
              <div className="flex" />
              {(this.state.status == StatusData.val2Berjalan || this.state.status == StatusData.val2Gagal) && <Button icon primary swapTheming onClick={()=> {
                if(this.rootPath) {
                  this.props.history.push(this.rootPath+"/val2");
                } else {
                  this.props.history.push('/reg_npwp/val2');
                }
              }}>navigate_next</Button>}

              {(!(this.state.status == StatusData.val2Berjalan || this.state.status == StatusData.val2Gagal) && !(this.state.status == StatusData.val1Berjalan || this.state.status == StatusData.val1Gagal)) && <Button icon primary style={{backgroundColor: '#4caf50'}} swapTheming>check</Button>}

              {(this.state.status == StatusData.val1Berjalan || this.state.status == StatusData.val1Gagal) && <Button icon primary style={{backgroundColor: '#bdbdbd'}} swapTheming disabled>remove</Button>}
            </div>
          <Divider/>
        </Card>

        <br/>

        <Card>
          {/* <CardTitle
            title="LENGKAPI DATA WP"
            subtitle={this.state.data.wp.namaWp}
            // avatar={<Avatar src={this.state.url} role="presentation" />}
          /> */}
          <div className="hbox md-card-title md-card-title--primary">
            <div className="md-card-title--title md-card-title--large md-text">LENGKAPI DATA WP</div>
            <div className="flex" />
        {(this.state.status == StatusData.dataWp || this.state.status == StatusData.dataWp || this.state.status == "GAGAL" || this.state.status == "DATA SIAP DI KIRIM") && <Button icon primary swapTheming onClick={()=> this.goToEdit()}>navigate_next</Button> }

        {(!(this.state.status == StatusData.dataWp || this.state.status == StatusData.dataWp || this.state.status == "GAGAL" || this.state.status == "DATA SIAP DI KIRIM")  && !(this.state.status == StatusData.val1Berjalan || this.state.status == StatusData.val1Gagal || this.state.status == StatusData.val2Berjalan || this.state.status == StatusData.val2Gagal)) && <Button icon primary style={{backgroundColor: '#4caf50'}} swapTheming>check</Button>}

        {(this.state.status == StatusData.val1Berjalan || this.state.status == StatusData.val1Gagal || this.state.status == StatusData.val2Berjalan || this.state.status == StatusData.val2Gagal) && <Button disabled icon primary style={{backgroundColor: '#bdbdbd'}} swapTheming>remove</Button>}
          </div>

          <Divider/>
          <List>
            <ListItem primaryText="Nama" secondaryText={this.state.data.wp.namaWp} />
            <ListItem primaryText="NIK" secondaryText={this.state.data.wp.noIDWp} />
            <ListItem primaryText="Email" secondaryText={this.state.data.wp.emailWp} />
            <ListItem primaryText="No. Telp" secondaryText={this.state.data.wp.nomorTelpWp} />
          </List>
          <Divider/>
          <div className="mpk-margin-N margin-bottom mpk-font-color-D2" style={{padding:10}}>
            <Checkbox
              id="checkbox-read-material-design-spec"
              onChange={(v, e)=> {this.setState({agree: v})}}
              name="eula"
              label={<div>Dengan melakukan sign-in, saya menyatakan telah membaca, mengerti, dan menyetujui <span className="mpk-link" onClick={(e)=> {e.preventDefault(); this.eula() }}>syarat dan ketentuan</span> yang berlaku</div>}
              value={this.state.agree}
              checked={this.state.agree}
            />
          </div>
          <Divider/>
          <div className="md-grid">

          </div>
          <CardActions>
            {/* {this.state.status == 'DATA WP BELUM DI ISI' && <Button flat onClick={()=> this.goToEdit()}>1. Isi Data WP</Button>} */}
            {/* {this.state.status != 'DATA WP BELUM DI ISI' && <Button flat onClick={()=> this.goToEdit()}>1. Ubah Data WP</Button>} */}

            {/* {this.state.agree && <Button flat primary onClick={()=> this.goSend()} swapTheming disabled={this.state.status == 'DATA WP BELUM DI ISI' || !this.state.agree}>Kirim Permohonan</Button>} */}
            <Button flat primary={this.state.agree && !(this.state.status == 'BERHASIL' || this.state.status == 'DATA WP BELUM DI ISI')} onClick={()=> this.goSend()} swapTheming disabled={this.state.status == 'DATA WP BELUM DI ISI' || this.state.status == 'BERHASIL' || !this.state.agree}>Kirim Permohonan</Button>
          </CardActions>
        </Card>
      </div>

      <div className="md-cell md-cell--6">
        <Card>
          <CardTitle
            title="NPWP"
            // subtitle="Card Subtitle"
            // avatar={<Avatar src='' role="presentation" />}
          />
          <Divider/>
          <div className="md-card-title--title md-card-title--large md-text" style={{padding: 15, fontSize: 18, textAlign: 'center'}}>
            {this.state.data.npwp}
          </div>
        </Card>
        <br/>

        {(this.state.status == "GAGAL" || this.state.status == "BERHASIL") && <Card>
          <CardTitle
            title="Respon"
            // subtitle="Card Subtitle"
            // avatar={<Avatar src='' role="presentation" />}
          />

          <Divider/>
          <List>
            <ListItem primaryText="Status" secondaryText={this.state.data.status} />
            <ListItem primaryText="Pesan" secondaryText={parse(this.state.data.message)} secondaryTextStyle={{whiteSpace: 'normal'}} />
          </List>
          <Divider />
          <List>
            <ListItem primaryText="NPWP" secondaryText={this.state.data.npwp} primaryTextStyle={{fontWeight: 'bold'}} secondaryTextStyle={{fontWeight: 'bold'}} />
            <ListItem primaryText="Kode KPP" secondaryText={this.state.data.kodeKpp} />
            <ListItem primaryText="Nama KPP" secondaryText={this.state.data.namaKpp} />
            <ListItem primaryText="Nomor Transaksi" secondaryText={this.state.data.nomorTransaksi} />
          </List>

        </Card>}
      </div>

    </div>
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div>
        <Button raised primary onClick={() => this.initData()}>REFRESH</Button>
      </div>
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      agree: false,
      data: {
        wp: {},
        domisili: {},
        ktp: {},
        klu: {},
        agreement: false
      },
      status: 'VALIDASI TAHAP 1 : BERJALAN',
      url: ''
    }
  }

  async initData() {
    let res = await this.service.api.me()
    if(!res.data.id) {
      res.data = {
        wp: {},
        domisili: {},
        ktp: {},
        klu: {},
        agreement: false
      }
    }

    var status = StatusData.val1Berjalan
    if(res.data.status && res.data.status != "") status = res.data.status

    this.setState({data: res.data, status, agree: res.data.agreement})

    console.log(res.data, 'asdasd', res.data.agreement, this.state)

    // console.log(res.data, 'asdasd')
    this.props.initialize(res.data);

    this.initImg()
  }

  async initImg() {
    var res = await uploadService.api.download(this.state.data.lokasiPoto)
    // console.log(res, 'asdasd')

    this.setState({url:URL.createObjectURL(res.data)})
  }

  goToEdit = () => {
    if(this.addDialog) {
      this.setState({showForm:true, formData: {}})
    } else {
      // if(env.devMode){
        if(this.rootPath) {
          this.props.history.push(this.rootPath+"/edit");
        } else {
          this.props.history.push('/'+this.service.name+"/edit");
        }
      // } else {
      //   if(this.props.match.url.search("reg_npwp") >= 0) {
      //     this.props.history.push(`${this.props.match.url}/edit`)
      //   } else {
      //     this.props.history.push(`${this.props.match.url}/reg_npwp/edit`)
      //   }
      // }
    }
  }


  goSend = async () => {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Kirim Data',
      text: "Apakah anda akan mengirim data wp?"
    }))

    if(p) {
      await this.service.api.send(this.state.data.id)
      iziToast.info({title: 'INFO', message: "Data berhasil dikirim, mohon di tunggu"})
      this.setState({status: 'MENUNGGU'})
      await this.initData()

      setTimeout(() => {
        this.initData()
      }, 4000)
    }
  }

  eula = async () => {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: '',
      width: 600,
      height: 600,
      actions: [{
        secondary: true,
        children: 'Tutup',
        onClick: ()=> {
          var result = props.formValue;
          if(!result) result = {}
          res(result)
        }
      }],
      text: <div>
        <Eula />
      </div>
    }))
  }

  dialogValidasiStep1 = async () => {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: '',
      width: 600,
      height: 600,
      okText: 'Submit',
      text: <div>
        {/* <Eula /> */}
      </div>
    }))
  }
}


