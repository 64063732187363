import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import KodeWilayahService from './KodeWilayah.service';
import ListView from '../../../components/entity/listView';
import moment from 'moment';

export class KodeWilayahView extends ListView {
  editDialog  = false;
  addDialog   = false;
  service     = KodeWilayahService;
  stream      = true;

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.id`,       value: "id"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.name`,       value: "name"},
    {isSortable: false,  show: false,   isSearchable:false,  label: `entities.${this.service.name}.noProp`,       value: "noProp"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.namaProp`,       value: "namaProp"},
    {isSortable: false,  show: false,   isSearchable:false,  label: `entities.${this.service.name}.noKab`,       value: "noKab"},
    {isSortable: false,  show: true,   isSearchable:false,  label: `entities.${this.service.name}.namaKab`,       value: "namaKab"},
    {isSortable: false,  show: false,   isSearchable:false,  label: `entities.${this.service.name}.noKec`,       value: "noKec"},
    {isSortable: false,  show: true,   isSearchable:false,  label: `entities.${this.service.name}.namaKec`,       value: "namaKec"},
    {isSortable: false,  show: false,   isSearchable:false,  label: `entities.${this.service.name}.noKel`,       value: "noKel"},

  ]
}

export default connect(KodeWilayahService.stateConnectSetting(), KodeWilayahService.actionConnectSetting())(KodeWilayahView)
