/**
 * Created by dwiargo on 11/29/17.
 */
import env from 'env';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Main from './modules/Main/Main';
import MainOri from './modules/Main/MainOri';
import Test from './modules/Main/Test'
import Error from './modules/Error/Error'
import Account from './modules/Account/Account'
import ResetPassword from './modules/Account/ResetPassword'

export default actions => {
  // console.log('tourte devmode', env.devMode)
  if(env.devMode) {

    return <Switch>
      <Route path="/account" component={Account}/>
      <Route path="/reset/finish" component={ResetPassword}/>
      <Route path="/" component={MainOri}/>
      <Route path="/error" render={(props) => (
        <Error router={props}/>
      )}/>
      <Route path="/company/:companyId/:subscriptionId/init" component={MainOri}/>
    </Switch>
  } else {
    return <Switch>
      <Route path="/account" component={Account}/>
      <Route path="/reset/finish" component={ResetPassword}/>
      <Route path="/main" component={Main}/>
      <Route path="/error" render={(props) => (
        <Error router={props}/>
      )}/>
      {/* <Route path="/" exact render={() => (
        <Redirect to="/main"/>
      )}/> */}
      <Route path="/" component={MainOri}/>

      <Route path="/company/:companyId/:subscriptionId/init" component={MainOri}/>
    </Switch>
  }
}


