import React,{ Component, PropTypes } from 'react';
import Translate from 'react-translate-component';
import counterpart from 'counterpart';
import _ from 'lodash';
import {Field} from 'redux-form';
import {TableColumn} from 'react-mpk';
import {initialize} from 'redux-form';
import {
  Tabs, Tab,
  List,
  ListItem,
  Avatar,
  FontIcon,
  Button
} from 'react-md';

import {validation, Searchfield, Textfield} from '../../components/form'

export default class TableSearchCustom extends Component {

  static contextTypes = {
    showDialog: 'React.PropTypes.func',
  };

  tabs = ["default", "customSearch"];

  constructor(){
    super();
    this.state = {
      currentTab: 0
    }
  }

  onSearchClick() {
    var param = this.props.tableFilter.reduce((r, d) => {
      var fields = d.fieldName.split('.');

      if(fields.length >= 2) {
        r[fields[1]+'.'+d.action] = d.value;
      } else {
        r[d.fieldName+'.'+d.action] = d.value;
      }

      r['specified'] = true;

      return r;
    }, {})



    this.props.onSearch(param)

  }

  async onSearchClick2() {
    this.props.table.params.page = 1;
    var param = {
      // ...this.props.table.params,
      sortBy: this.props.table.params.sortBy,
      sort: this.props.table.params.sort,
      // page: this.props.table.params.page,
      size:this.props.table.params.size,
    }

    this.props.dispatch(this.props.service.tableAction.setProperties({

        params: {
          ...this.props.table.params,
          page: 1
        }

    }))

    var inData = {}
    _.forOwn(this.props.tableFilter2, function(value, key) {
      var n = key.split('__');
      if(n.length === 2) {
        if(n[1].search('in') >= 0 && n[1] !== 'contains') {
          if(value) {
            if(!inData[n[0]]) {
              inData[n[0]] = '';
            }

            inData[n[0]] += n[1].split('_')[1]+", ";
          }
        } else {
          param[n[0]+'.'+n[1]] = value;
        }
      } else {
        param[n] = value;
      }
    });

    Object.keys(inData).forEach((v, i)=> {
      param[v+'.in'] = inData[v];
    })

    if(this.props.tableFilter2 && this.props.tableFilter2.column && this.props.tableFilter2.searchKey) {
      param[this.props.tableFilter2.column] = this.props.tableFilter2.searchKey;
    }

    await this.props.service.api.updateFilterObj(param, this.props.dispatch);

    this.props.onSearch(param)
  }

  onTabChange = (index, tabId) => {
    this.setState({currentTab:index});
  };

  onDeleteSearch(i) {
    var tableFilter = _.cloneDeep(this.props.tableFilter);
    tableFilter.splice(i, 1);
    this.props.service.api.updateFilter(tableFilter, this.props.dispatch);
  }

  async onAddSearch(data={}, i) {

    var fieldOptions = this.props.columns.filter((v) => v.isSearchable).map((d, i) => {
      var id = d.value;
      // if(typeof id === 'function') id = id();
      if(d.searchField) id = d.searchField

      return {
        id: id,
        name: counterpart.translate(d.label)
      }
    })

    fieldOptions.splice(-1,1);

    var res = await this.context.showDialog(()=> ({
      title: 'Tambah Pencarian',
      initialValue:data,
      contentProps: {
        style: {
          maxHeight: 300
        }
      },
      text: (
        <div>
          <Field
            label='Field Name'
            name='fieldName'
            className="md-cell md-cell--12"
            options={fieldOptions}
            component={Searchfield}
          />
          <Field
            label='Action'
            name='action'
            className="md-cell md-cell--12"
            options={[{id: 'contains', name: 'contains'}, {id: 'equals', name: 'equals'}]}
            component={Searchfield}
          />
          <Field
            label='Value'
            name='value'
            className="md-cell md-cell--12"
            component={Textfield}
            />
        </div>
      )
    }))

    if(res) {
      var tableFilter = _.cloneDeep(this.props.tableFilter);
      if(i || i === 0) {
        tableFilter[i] = _.cloneDeep(res);
      } else {
        tableFilter.push(_.cloneDeep(res));
      }


      this.props.service.api.updateFilter(tableFilter, this.props.dispatch);
    }
  }

  switchTab() {

    switch (this.state.currentTab) {
      case 0:
        return (
          this.defaultTab()
        );
      case 1:
        return (
          this.searchTab()
        );
      default:
        return (null);
    }
  }

  defaultTab() {
    return (
      <div className='mpk-layout column fill'>
        <div className="flex">
        {this.props.searchForm}
        </div>


        <div className="mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark">
          <Button className='flex' raised primary onClick={()=> this.onSearchClick2()}><Translate content={"word.search"}/></Button>
          <Button className='flex' flat primary onClick={async ()=> {
            await this.props.dispatch(initialize(this.props.service.name+'_search', {}));
            await this.props.service.api.updateFilterObj({}, this.props.dispatch);
            this.props.onSearch()
          }}>Clear</Button>

          {/* <Button icon iconClassName='mdi mdi-plus' onClick={() => this.onAddSearch()}></Button> */}
        </div>
      </div>
    )
  }

  searchTab() {
    return (
      <div className='mpk-layout column fill'>
        <div className='flex'>
          <List>
            {this.props.tableFilter.map((d, i) => {
              return (
                <ListItem
                  key={i}
                  onClick={()=> this.onAddSearch(d, i)}
                  rightIcon={<FontIcon onClick={(e)=> {this.onDeleteSearch(d, i); e.stopPropagation()}}>delete</FontIcon>}
                  primaryText={d.fieldName}
                  secondaryText={`${d.action}\n${d.value}`}
                  threeLines
                />
              )
            })}
          </List>
        </div>
        <div className="mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark">
          <Button className='flex' raised primary onClick={()=> this.onSearchClick()} style={{marginRight: 5}}><Translate content={"word.search"}/></Button>
          {/* <div className='flex'/> */}
          <Button className='flex' flat iconClassName='mdi mdi-plus' onClick={() => this.onAddSearch()} style={{marginLeft: 5}}>Add</Button>
        </div>
      </div>
    )
  }

  render() {
    return(
      <div className="mpk-full height mpk-layout column">
        <div className="flex">
          {this.switchTab()}
        </div>

        {/* <Tabs className='mpk-tab-white' tabId="simple-tab" mobile={true} onTabChange={this.onTabChange}>
          {this.tabs.map((d, i) => (
            <Tab key={i} label={(
              <Translate content={"word."+String(d)}/>
            )}/>
          ))}
        </Tabs> */}
      </div>
    )
  }


}
