import React, { Component } from 'react';
import createHistory from 'history/createHashHistory';
import createBrowserHistory from 'history/createBrowserHistory';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Router from 'react-router-dom/Router';
import picturefill from 'picturefill'
import * as globalActions from 'react-mpk/redux/actions/globalActions';
import id from './locale/id.json';
import en from './locale/en.json';
import translate from 'counterpart';

import './App.scss';

import env from 'env';
import { authService, navService, locale } from 'react-mpk';

import routes from './routes';
import DialogService from './services/dialog.service';

var history = createHistory();
if(!env.devMode){
  history = createBrowserHistory();
}

import { localStorageService, ssoConnect, rdxConnect, httpService, Dialog, mpkEnv } from 'react-mpk';
import { hosts } from './services';
import utilsService from './services/utilsService';

// mpkEnv.setPortal({channel:'ereg'})

@connect((state) => ({
  setting: state.authHalona.setting
}))
class App extends Component {
  componentWillMount() {
    //SET HTTP INTERCEPTOR
    httpService.setInterceptors((config)=> {
      let accessToken = authService.getAccessToken();
      if (accessToken) {
        config.headers = {
          Authorization: 'Bearer ' + accessToken
        }
      }
      return config;
    });
    //##

    //SET ERROR HANDLER
    httpService.setErrorHandler( err => {
      err.message = err.response.data ? (err.response.data.message) : err.message;
      return err;
    });
    //##

    //SET ENV
    // authService.setEnv(env);
    navService.init(history);
    //##

    //SET LOCALE\
    translate.registerTranslations('id', id);
    translate.registerTranslations('en', en);
    locale.init('en', en);
    locale.init('id', id)

    // sso
    this.props.globalActions.setProperties({
      localeCode:'id',
      localeList:[
        {label:'Bahasa', code:'id'},
        {label:'English', code:'en'},
      ]
    });


    picturefill();
  }

  render() {

    return (
      <div className="App" style={{
        zoom: this.props.setting.size
      }}>
        {/* <pre>
          {JSON.stringify(this.props.auth, null, 2)}
        </pre> */}
        <DialogService>
        <Dialog/>
        <Router history={history}>
          {routes()}
        </Router>
        </DialogService>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  global:state.global
});
const mapDispatchToProps = dispatch => ({
  globalActions:bindActionCreators(globalActions, dispatch)
})
// console.log(env, 'env')

var build = function() {
  if(env.devMode) {
    return connect(mapStateToProps, mapDispatchToProps)(App);
  } else {
    return ssoConnect({
      exchangeToken:'/api/iams/exchangeToken',
      refreshToken:'/api/iams/refreshToken/:refreshToken',
      login:'/api/iams/login',
      me:'/api/iams/me'
    },
      () => (hosts.getAll().iam.host),
      () => (hosts.getAll().iam.credentials),
      history,
      user => {
        console.log(user, 'user')
        if(user && user.product) {utilsService.setReqOptionsBaseUrl(user.product.baseUrl)}

      }
    )(rdxConnect(App));
  }
}

export default build()


