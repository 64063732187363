import ApiService from '../../../services/api.service';

class NpwpNikService extends ApiService {
  name  = 'NpwpNik';
  path  = 'npwp_nik';
  constructor() {
    super();
    this.init()
  }

  api = {
  }
}


export default new NpwpNikService();
