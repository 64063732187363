import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import LogProcessService from './LogProcess.service';
import ListView from '../../../components/entity/listView';
import LogProcessDialog from './LogProcess.dialog'
import download from "downloadjs";

@connect(LogProcessService.stateConnectSetting(), LogProcessService.actionConnectSetting())
export default class LogProcessView extends ListView {
  service     = LogProcessService
  FormDialog  = LogProcessDialog

  async beforeFetch(params) {

      // params.module=["Send Email Salary Slip", "PPh21 Eppt Core"],
      // params.start = "01/01/2018"
      // params.end   = "01/01/5020"
    }

  columns=[
      {isSortable: true,    show: true,   isSearchable:false,    label: "entities.logValidasi.logType",                value: "logType"},
      {isSortable: true,    show: true,   isSearchable:false,    label: "entities.logValidasi.status",                 value: "status"},
      {isSortable: true,    show: true,   isSearchable:false,    label: "entities.logValidasi.ip",                     value: "ip"},
      {isSortable: true,   show: true,   isSearchable:false,    label: "entities.logValidasi.reqData",                value: "reqData"},
      {isSortable: true,   show: true,   isSearchable:false,   label: "entities.logValidasi.resData",                value: "resData"},
      {isSortable: true,   show: true,   isSearchable:false,   label: "entities.logValidasi.user",                   value: "user"},
      {isSortable: true,   show: true,   isSearchable:false,   label: "entities.logValidasi.validateDate",           value: "validateDate", isDefaultSort: true}
  ]

  tableActions = (d)=>{

    var menu =  [
    ]

    return menu
  }

  _barActions = () => {
    var arr = [
      {
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem()
      },
      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => {

          window.location.hash = window.location.hash.split('?')[0]
          this.fetchData()
        }
      },
      {
        label:'word.export',
        iconClassName:'mdi mdi-export',
        onClick:() => this.export()
        // disabledFunc:() => this.props.table.selected.length === 0
      }]
    return arr
  }

  export = async () => {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Export',
      width: 500,
      initialValue: {},
      contentStyle: {overflow: 'inherit', padding: 0},
      okText: 'Submit',
      text: <div className="md-grid">
        <this.ig.Field
          label         = 'Dari Tanggal'
          name          = 'from'
          className     = 'md-cell md-cell--12'
          component     = {this.ig.Datepicker}
        />
        <this.ig.Field
          label         = 'Sampai Tanggal'
          name          = 'to'
          className     = 'md-cell md-cell--12'
          component     = {this.ig.Datepicker}
        />
      </div>
    }));

    if(d) {
      var res = await this.service.api.download(d)
      var filename = res.headers['content-disposition'].split('filename=').pop();
      download(res.data, filename)
    }
  }



}
