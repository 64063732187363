import * as CONSTANT from '../../config/constant';

const mainMenus = [{
  label: {
    id: 'Menu Utama',
    en: 'Main'
  },
  children: [
  // {
  //   label: {
  //     id: 'Registrasi NPWP',
  //     en: 'Registrasi NPWP'
  //   },
  //   iconClassName: 'mdi mdi-view-grid',
  //   path: '/EregData'
  // },
  {
    label: {
      id: 'Permohonan Pendaftaran NPWP',
      en: 'Permohonan Pendaftaran NPWP'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/reg_npwp'
  },
  // {
  //   label: {
  //     id: 'Log Validasi',
  //     en: 'Validation Log'
  //   },
  //   iconClassName: 'mdi mdi-view-grid',
  //   path: '/log-validasi'
  // }
]
},
// {
//   label: {
//     id: 'Menu Lainnya',
//     en: 'Others'
//   },
//   children: [{
//     label: {
//       id: 'Validasi NIK',
//       en: 'NIK Validation'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/dashboard'
//   },
//   {
//     label: {
//       id: 'Validasi NIK & Data Balikan',
//       en: 'Validasi NIK & Data Balikan'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/validasi-balikan'
//   }]
// },
{
  label: {
    id: 'Pengaturan',
    en: 'Pengaturan'
  },
  children: [{
    label: {
      id: 'User Console',
      en: 'User Console'
    },
    iconClassName: 'mdi mdi-view-grid',
    onClick: ()=> {window.open('http://user.sobatpajak.com/main/account/user')},
    // onClick: ()=> {window.open('http://user.bdg.pajakku.com/main/account/user')},
    // path: 'http://user.bdg.pajakku.com/main/account/user'
  }]
}]

export default {
  mainMenus
};
