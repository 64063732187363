import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import NpwpNikService from './NpwpNik.service';
import ListView from '../../../components/entity/listView';
import NpwpNikDialog from './NpwpNik.dialog'
import download from "downloadjs";

@connect(NpwpNikService.stateConnectSetting(), NpwpNikService.actionConnectSetting())
export default class NpwpNikView extends ListView {
  service     = NpwpNikService
  FormDialog  = NpwpNikDialog

  columns=[
      {isSortable: true,    show: true,   isSearchable:false,    label: "entities.NpwpNik.npwp",                value: "npwp"},
      {isSortable: true,    show: true,   isSearchable:false,    label: "entities.NpwpNik.nik",                value: "nik"},
  ]

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.delete(item.npwp);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  _tableActions = [

    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]
}
