import ApiService from '../../../services/api.service';

class EditProfileService extends ApiService {
  name= 'editProfile';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    get: async () => {
      var res = await this.http.get(`${this.apiLocation}/account/me`)

      return res;
    },
    update: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/account`, data)

      return res;
    },
  }
}

export default new EditProfileService();
