import ApiService from '../../../services/api.service';

class KodeWilayahService extends ApiService {
  name  = 'KodeWilayah';
  path  = 'kode_wilayah';
  constructor() {
    super();
    this.init()
  }

  api = {
    me: async ()=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}_me`)
      return res
    },
    updateMe: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}_me`, data)
      return res
    },
    send: async (id)=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}_send/${id}`)
      return res
    },
  }
}


export default new KodeWilayahService();
