import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
List,
ListItem,
TextField
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch} from '../../../components/form';
import DialogView from '../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import NpwpNikService from './NpwpNik.service';

@reduxForm({form: 'NpwpNikForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
data: getFormValues('NpwpNikForm')(state)
}))
export default class NpwpNikDialog extends DialogView {
service     = NpwpNikService
// showSubmit  = false
cancelLabel = 'Tutup'

formView() {
    return (
    <div className='md-grid'>
        <Field
        label         = 'NPWP'
        name          = 'npwp'
        className     = 'md-cell md-cell--12'
        component     = {TextfieldMask}
        maskFormat    = "##.###.###.#-###-###"
        mask          = "_"
        length        = {15}
        validate      = {validation.required}
        />
        <Field
        label         = "NIK"
        name          = 'nik'
        className     = 'md-cell md-cell--12'
        component     = {TextfieldMask}
        maskFormat    = "################"
        mask          = "_"
        length        = {16}
        validate      = {validation.required}
        />
    </div>
    )
}

initData(props) {
  if(props.formData.npwp) {
    this.props.initialize(props.formData);
  } else {
    this.props.initialize(this.initialData);
  }
}
}
